@import '../../includes/all';

#container {
  text-align: center;

  > div {
    font-size: 26px;
  }
}

h1 {
  font-size: 120px;
  color: $theme-text;
  text-shadow: none !important;
}

pre {
  text-align: left;
  overflow: auto;
}
